<template>
  <ContentSection
    :padding="data.padding?.value"
    :class="{ '!pb-0': !nextComponent }"
  >
    <Container
      indent="none"
      class="relative px-container-wide md:px-container-desktop-wide"
    >
      <!-- BACKGROUND IMAGE -->
      <div class="absolute inset-0">
        <AppImage
          class="h-full w-full object-cover object-top"
          :src="data.image || component_newsletter_defaults.image"
          :alt="
            $i18n.locale === 'fr'
              ? data.image?.alt_fr ||
                component_newsletter_defaults.image?.alt_fr
              : data.image?.alt || component_newsletter_defaults.image?.alt
          "
          loading="lazy"
        />
      </div>

      <!-- GRADIENT -->
      <div
        class="absolute inset-0 bg-gradient-to-t from-[#022638]/70 via-[#022638]/20 to-[#022638]/70"
      />

      <div
        class="relative grid grid-cols-1 gap-12 py-24 text-white lg:py-32 xl:grid-cols-2 xl:gap-48"
      >
        <div>
          <!-- TITLE -->
          <h2
            class="mb-6 text-3xl font-medium sm:mb-9 sm:text-5xl"
            v-html="
              $replaceHtmlTags(
                data.heading || component_newsletter_defaults.heading
              )
            "
          />
          <!-- TEXT -->
          <div
            class="mb-12 text-base sm:mb-9 sm:text-lg"
            v-html="data.text || component_newsletter_defaults.text"
          />
          <div
            v-if="data.enrol_for.value === 'both'"
            class="flex flex-wrap gap-x-10 gap-y-5"
          >
            <!-- NEWSLETTER CHECKBOX -->
            <Checkbox
              :model-value="newsletter_checked"
              :disabled="media_message_checked === false"
              class="text-base sm:text-lg"
              is-white
              @update:model-value="updateFormFields($event, 'newsletter')"
            >
              {{
                data.newsletter_checkbox_text ||
                component_newsletter_defaults.newsletter_checkbox_text
              }}
            </Checkbox>

            <!-- MEDIA MESSAGE CHECKBOX -->
            <Checkbox
              :model-value="media_message_checked"
              is-white
              class="text-base sm:text-lg"
              @update:model-value="updateFormFields($event, 'media_messages')"
            >
              {{
                data.newsletter_checkbox_text ||
                component_newsletter_defaults.media_message_checkbox_text
              }}
            </Checkbox>
          </div>
        </div>

        <!-- FORM -->
        <div>
          <Form
            key="form"
            ref="form"
            v-slot="{ errors }"
            novalidate
            class="grid grid-cols-4 gap-3 text-blue-dark-900 sm:grid-cols-12 sm:gap-7"
            @submit="submit"
          >
            <template v-for="inputField in newsletterOrMediaForm.fields">
              <!-- TEXT -->
              <InputField
                v-if="
                  inputField.type === 'text' ||
                  inputField.type === 'email' ||
                  inputField.type === 'integer'
                "
                :key="inputField.handle"
                :field="inputField"
                :label="$t(`newsletter_form.${inputField.handle}`)"
                :rules="newsletterOrMediaForm.rules[inputField.handle]"
                :error="errors[inputField.handle]"
              />

              <!-- SELECT -->
              <AppSelect
                v-if="inputField.type === 'select'"
                :key="inputField.handle"
                :field="inputField"
                :label="$t(`newsletter_form.${inputField.handle}`)"
                :rules="newsletterOrMediaForm.rules[inputField.handle]"
                :error="errors[inputField.handle]"
                :options="inputField.config.options"
                :placeholder="$t('salutation')"
              />
            </template>

            <!-- HONEYPOT -->
            <input class="hidden" :value="newsletterOrMediaForm.honeypot" />

            <!-- SUBMIT BUTTON -->
            <div
              v-if="state !== 'submitted'"
              class="col-span-12 mt-3 flex items-center justify-self-start sm:mt-6"
            >
              <AppButton type="submit" :is-loading="state === 'pending'">
                {{
                  data.link_button_text ||
                  component_newsletter_defaults.link_button_text
                }}
              </AppButton>
            </div>

            <!-- ALERT BANNER -->
            <Transition mode="out-in">
              <AlertBanner
                v-if="state === 'submitted' || state === 'error'"
                class="col-span-12 mt-10"
                :type="state"
              >
                {{
                  data.message_success ||
                  component_newsletter_defaults.message_success
                }}

                <template #error>
                  {{
                    data.message_error ||
                    component_newsletter_defaults.message_error
                  }}
                </template>
              </AlertBanner>
            </Transition>
          </Form>
        </div>
      </div>
    </Container>
  </ContentSection>
</template>

<script setup>
import { Form } from 'vee-validate'
provide('hasBackgroundImage', true)

const props = defineProps({
  data: { type: Object, required: true },
  nextComponent: { type: String, default: null },
})

const { locale } = useI18n()
const { component_newsletter_defaults } = useGlobalsStore()

// REFS
const form = ref(null)
const newsletter_checked = ref(true)
const media_message_checked = ref(false)
const state = ref('idle')

// COMPUTED
const newsletterOrMediaForm = computed(() => {
  if (props.data.enrol_for.value === 'newsletter') {
    return props.data.form_newsletter
  }
  if (props.data.enrol_for.value === 'media_messages') {
    return props.data.form_media_messages
  }

  if (newsletter_checked.value && !media_message_checked.value) {
    return props.data.form_newsletter
  }
  if (newsletter_checked.value && media_message_checked.value) {
    return props.data.form_media_messages
  }

  return props.data.form_media_messages
})

// FUNCTIONS
async function updateFormFields(target, type) {
  if (type === 'newsletter') {
    newsletter_checked.value = target.checked
  } else if (type === 'media_messages') {
    media_message_checked.value = target.checked
  } else if (!newsletter_checked.value && !media_message_checked.value) {
    newsletter_checked.value = true
  }
}

async function submit(values) {
  state.value = 'loading'

  const config = useRuntimeConfig()

  // send language as well
  values.language = locale.value

  try {
    if (newsletter_checked.value) {
      await $fetch(`${config.public.API_URL}/!/forms/form_newsletter`, {
        method: 'POST',
        body: values,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
    }
    if (media_message_checked.value) {
      await $fetch(`${config.public.API_URL}/!/forms/form_media_messages`, {
        method: 'POST',
        body: values,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
    }

    form.value.resetForm()

    state.value = 'submitted'
  } catch (e) {
    state.value = 'error'
    console.error(e)
  }
}
</script>
